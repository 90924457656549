<template>
  <div>
    <component :is="layout">
      <div class="card shadow bg-white p-2 m-3">
        <span v-if="message">
          <p class="text-center alert-success">{{ message }}</p>
        </span>
        <h3 class="text-center">Daily Games</h3>
        <table class="table table-responsive table-stripped">
          <tr>
            <td>name</td>
            <td>result</td>
            <td>status</td>
            <td>actions</td>
          </tr>

          <tr v-for="(daily_game, index) in daily_games" :key="daily_game.id">
            <td>{{ daily_game.name }}</td>
            <td>{{ daily_game.result }}</td>
            <td
              @click="toggleDailyGameStatus(daily_game.id)"
              class="m-1 btn btn-sm btn-success bg-success"
              v-if="daily_game.status"
            >
              ✓
            </td>
            <td v-else>
              <button
                @click="toggleDailyGameStatus(daily_game.id)"
                class="m-1 btn btn-sm btn-danger bg-danger"
              >
                ✕
              </button>
            </td>
            <td>
              <button
                id="show-modal"
                @click="edit(index)"
                class="btn bg-primary btn-sm btn-primary m-1"
              >
                Edit
              </button>
              <button
                @click="showPanel(index)"
                class="btn btn-sm bg-warning btn-warning m-1"
              >
                Add Panel
              </button>
              <button
                @click="select_panel(index)"
                class="btn btn-sm bg-success btn-success bg-success m-1"
              >
                Select
              </button>
            </td>
          </tr>
        </table>
      </div>
      <!-- model -->
      <modal :showModal="showEditModal">
        <template v-slot:header>
          <h3 class="text-center">{{ editDailyGame.name }}</h3>
        </template>
        <template v-slot:body>
          <div class="form-group">
            <label for="name">Name</label>
            <input
              placeholder="name"
              class="form-control"
              v-model="editDailyGame.name"
            />
          </div>
          <div class="form-group">
            <label for="time">Time</label>
            <input
              placeholder="time"
              class="form-control"
              v-model="editDailyGame.time"
            />
          </div>
          <div class="form-group">
            <label for="result">Result</label>
            <input
              placeholder="result"
              class="form-control"
              v-model="editDailyGame.result"
            />
          </div>
          <div class="form-group">
            <label for="color">Color</label>
            <input
              placeholder="color"
              class="form-control"
              v-model="editDailyGame.color"
            />
          </div>
        </template>
        <template v-slot:footer>
          <button
            @click="update()"
            class="btn btn-success bg-success modal-default-button"
          >
            Update
          </button>
          <button
            class="btn btn-danger bg-danger modal-default-button"
            @click="showEditModal = false"
          >
            close
          </button>
        </template>
      </modal>
      <!-- show panel modal -->
      <modal :showModal="showPanelModal">
        <template v-slot:header>
          <h3 class="text-center">{{ panel.name }}</h3>
          <p>{{ panel.days }} day panel</p>
        </template>
        <template v-slot:body>
          <span v-if="errors.length">
            <b>Please correct the following error(s):</b>
            <p class="alert-danger" v-for="error in errors" :key="error">
              {{ error }}
            </p>
          </span>
          <label for="">Start Date</label>
          <date-picker v-model="from_date" format="DD-MM-YYYY"></date-picker>
          <label for="">End Date</label>
          <date-picker v-model="to_date" format="DD-MM-YYYY"></date-picker>
        </template>
        <template v-slot:footer>
          <button
            @click="addPanel()"
            class="btn btn-success bg-success modal-default-button"
          >
            Add Panel
          </button>
          <button
            class="btn btn-danger bg-danger modal-default-button"
            @click="showPanelModal = false"
          >
            close
          </button>
        </template>
      </modal>
      <!-- update panel modal -->

      <modal :showModal="showUpdatePanelModal">
        <template v-slot:header>
          <h3 class="text-center">{{ daily_game.name }}</h3>
        </template>
        <template v-slot:body>
          <div class="d-flex justify-content-around">
            <div class="form-group">
              <label for="">Monday</label>
              <input
                type="text"
                size="10"
                v-model="currentPanelData.monday"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="">Tuesday</label>
              <input
                type="text"
                size="10"
                v-model="currentPanelData.tuesday"
                class="form-control"
              />
            </div>
          </div>
          <div class="d-flex justify-content-around">
            <div class="form-group">
              <label for="">Wednesday</label>
              <input
                type="text"
                size="10"
                v-model="currentPanelData.wednesday"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="">Thursday</label>
              <input
                type="text"
                size="10"
                v-model="currentPanelData.thursday"
                class="form-control"
              />
            </div>
          </div>
          <div class="d-flex justify-content-around">
            <div class="form-group">
              <label for="">Friday</label>
              <input
                type="text"
                size="10"
                v-model="currentPanelData.friday"
                class="form-control"
              />
            </div>
            <div v-if="'saturday' in currentPanelData" class="form-group">
              <label for="">Saturday</label>
              <input
                type="text"
                size="10"
                v-model="currentPanelData.saturday"
                class="form-control"
              />
            </div>
          </div>

          <div v-if="'sunday' in currentPanelData" class="form-group">
            <label for="">Sunday</label>
            <input
              type="text"
              v-model="currentPanelData.sunday"
              class="form-control"
            />
          </div>
        </template>
        <template v-slot:footer>
          <button
            @click="updatePanel(currentPanelData.id)"
            class="btn btn-success bg-success bg-success modal-default-button"
          >
            Update Panel
          </button>
          <button
            class="btn bg-danger btn-danger bg-danger modal-default-button"
            @click="showUpdatePanelModal = false"
          >
            close
          </button>
        </template>
      </modal>
      <!-- daily panel section -->

      <div v-if="panelData != Array" class="card bg-white shadow p-2 m-3">
        <table class="table table-responsive">
          <tr>
            <td>From</td>
            <td>To</td>
            <td>Action</td>
          </tr>
          <tr v-for="(item, index) in panelData.panel.data" :key="item.id">
            <td>{{ item.from_date }}</td>
            <td>{{ item.to_date }}</td>
            <td>
              <button
                @click="editPanel(item.id, index)"
                class="btn btn-primary bg-primary btn-sm m-1"
              >
                Edit
              </button>
              <button
                @click="deletePanel(item.id)"
                class="btn btn-danger bg-danger bg-danger btn-sm m-1"
              >
                Delete
              </button>
            </td>
          </tr>
        </table>
        <pagination
          v-if="panelData[0]"
          class=""
          :per-page="10"
          v-model="page"
          :records="panelData.panel.total"
          @paginate="getPanel"
        />
      </div>
      <div class="card shadow bg-white p-2 m-3">
        <span v-if="message">
          <p class="text-center alert-success">{{ message }}</p>
        </span>
        <h3 class="text-center">Daily Games</h3>
        <table class="table table-responsive table-stripped">
          <tr>
            <td>name</td>
            <td>Result</td>
          </tr>
          <draggable
            v-if="daily_games[0]"
            v-model="daily_games"
            @change="updateDailyGameOrder"
          >
            <tr v-for="daily_game in daily_games" :key="daily_game.id">
              <td>{{ daily_game.name }}</td>
              <td>{{ daily_game.result }}</td>
            </tr>
          </draggable>
        </table>
      </div>
    </component>
  </div>
</template>

<script>
import Modal from "../Modal.vue";
import DatePicker from "vue2-datepicker";
import draggable from "vuedraggable";
import "vue2-datepicker/index.css";
import Pagination from "vue-pagination-2";
import axiosConfig from '../../axiosConfig';
import axiosObject from '../../axiosHandler';

export default {
  components: {
    Modal,
    DatePicker,
    draggable,
    Pagination,
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },

  data() {
    return {
      daily_games: Object,
      daily_game: Object,
      showEditModal: false,
      showPanelModal: false,
      showUpdatePanelModal: false,
      current_game: null,
      editDailyGame: Object,
      panel: Object,
      from_date: null,
      to_date: null,
      errors: [],
      message: null,
      panelData: Array,
      day: null,
      page: 1,
      currentPanelData: Object,
      creating:false
    };
  },
  created() {
    this.getDailyGames();
  },
  methods: {
    getDailyGames() {
      axiosObject.get("/api/admin/daily_games",axiosConfig)
        .then(
          function (response) {
            this.daily_games = response.data.daily_games;
          }.bind(this)
        )
        .catch(function (error) {
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
        });
    },
    toggleDailyGameStatus(id) {
      axiosObject.post("/api/admin/toggle_daily_game_status/" + id,axiosConfig)
        .then(
          function (response) {
            this.getDailyGames();
          }.bind(this)
        )
        .catch(function (error) {
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
        });
    },
    edit(index) {
      this.showEditModal = true;
      this.current_game = index;
      this.editDailyGame = this.daily_games[index];
    },
    showPanel(index) {
      this.showPanelModal = true;
      this.current_game = index;
      this.panel = this.daily_games[index];
    },
    addPanel() {
      this.errors = [];
      const day = this.panel.days;
      this.panel.from_date = this.from_date;
      this.panel.to_date = this.to_date;
      if (!this.panel.from_date || !this.panel.to_date) {
        this.errors.push("From and To Dates are Required.");
        return;
      }

      if(this.creating == false)
      {
        this.creating = true
        axiosObject.post("/api/admin/day" + day + "panel", this.panel,axiosConfig)
        .then(
          function (response) {
            this.showPanelModal = false;
            this.creating = false
            this.message = response.data.message;
          }.bind(this)
        )
        .catch(function (error) {
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
          this.creating = false
        }.bind(this));
      }
    },
    update() {
      axiosObject.post("/api/admin/daily_game/", this.editDailyGame,axiosConfig)
        .then(
          function (response) {
            this.getDailyGames();
            this.showEditModal = false;
          }.bind(this)
        )
        .catch(function (error) {
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
        });
    },
    select_panel(index) {
      this.current_game = index;
      this.daily_game = this.daily_games[index];
      this.day = this.daily_game.days;
      axiosObject.get("/api/admin/day" + this.day + "panel?id="+this.daily_game.id,axiosConfig)
        .then(
          function (response) {
            this.panelData = response.data;
          }.bind(this)
        )
        .catch(function (error) {
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
        });
    },
    getPanel(page = 1) {
      this.page = page;
      axiosObject.get("/api/admin/day" + this.day + "panel?page=" + this.page,axiosConfig)
        .then(
          function (response) {
            this.panelData = response.data;
            console.log(response.data);
          }.bind(this)
        )
        .catch(function (error) {
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
        });
    },
    editPanel(id, index) {
      this.currentPanelData = this.panelData.panel.data[index];
      this.showUpdatePanelModal = true;
    },
    updatePanel(id) {
      axiosObject.patch(
          "/api/admin/day" + this.day + "panel/" + id,
          this.currentPanelData,axiosConfig
        )
        .then(
          function (response) {
            console.log(response.data);
            this.showUpdatePanelModal = false;
            this.getPanel(this.page);
          }.bind(this)
        )
        .catch(function (error) {
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
        });
    },
    deletePanel(id) {
      if (confirm("Do you really want to delete?")) {
        axiosObject
          .delete("/api/admin/day" + this.day + "panel/" + id,axiosConfig)
          .then(
            function (response) {
              this.getPanel(this.page);
              console.log(response.data);
            }.bind(this)
          )
          .catch(function (error) {
           alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
          });
      }
    },
    updateDailyGameOrder() {
      this.daily_games.map((daily_game, index) => {
        daily_game.sorting_id = index + 1;
      });
      axiosObject.post("/api/admin/daily_games_sorting", {
          daily_games: this.daily_games,
        },axiosConfig)
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
        });
    },
  },
};
</script>

<style>

</style>
